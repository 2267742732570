<template>
  <!-- eslint-disable vue/html-closing-bracket-newline -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/html-self-closing -->
  <!-- eslint-disable-next-line vue/valid-template-root -->
  <b-row>
    <b-col cols="12">
      <Fieldset id="information" :title="$t('Basic Information')">
        <h2>Customizing shipping</h2>
        <p>Sellect one of kinds of shipping method of Customizing shipping method</p>
      </Fieldset>
    </b-col>
    <b-col cols="12">
      <Fieldset id="options" :title="$t('Price based rates')">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2>Price based rates</h2>
            <p>Add rates based on the price of a customer's order</p>
          </div>
          <div class="d-flex align-items-center">
            <div v-if="!itemsPrice">
              <b-button
                variant="primary"
                type="submit"
              >
                Set up Price based rates
              </b-button>
            </div>
            <div class="d-flex ml-2">
              <b-form-checkbox
                v-model="model.shipping_mode"
                checked="true"
                class="custom-control-success"
                name="check-button"
                :value="1"
                switch
                @change="handleChangeShippingMode"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
              <span v-if="priceActive">On</span>
              <span v-else>Off</span>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <b-table v-if="itemsPrice.length > 0" responsive="sm" :fields="fieldsPrice" :items="itemsPrice">
            <template #cell(range)="item">
              {{ currencyFormat(item.item.min_price) }} - {{ currencyFormat(item.item.max_price) }}
            </template>
            <template #cell(amount)="item">
              {{ currencyFormat(item.item.amount) }}
            </template>
            <template #cell(more)="item">
              <div class="text-center d-flex">
                <b-link
                  v-b-modal.modal-price
                  class="text-secondary mx-1"
                  @click="getData(item.item.id, 1)"
                >
                  <feather-icon icon="Edit3Icon" class="mr-50" />
                </b-link>
                <div class="text-danger" @click="handleDelete(item.item.id, 1)">
                  <feather-icon icon="TrashIcon" />
                </div>
              </div>
            </template>
          </b-table>
          <div>
            <div>
              <b-button
                v-b-modal.modal-price
                variant="primary"
                type="submit"
              >
                Add rate
              </b-button>
            </div>
          </div>
        </div>
      </Fieldset>
    </b-col>
    <b-col cols="12">
      <Fieldset id="options2" :title="$t('Weight based rates')">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2>Weight based rates</h2>
            <p>Add rates based on the weight of products</p>
          </div>
          <div class="d-flex align-items-center">
            <div v-if="!itemsWeight">
              <b-button
                variant="primary"
                type="submit"
              >
                Set up Weight based rates
              </b-button>
            </div>
            <div class="d-flex ml-2">
              <b-form-checkbox
                v-model="model.shipping_mode"
                checked="true"
                class="custom-control-success"
                name="check-button"
                switch
                :value="2"
                @change="handleChangeShippingMode"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
              <span v-if="weightActive">On</span>
              <span v-else>Off</span>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <b-table v-if="itemsWeight.length > 0" responsive="sm" :fields="fieldsWeight" :items="itemsWeight">
            <template #cell(range)="item">
              {{ item.item.min_weight }} kg - {{ item.item.max_weight }} kg
            </template>
            <template #cell(amount)="item">
              {{ currencyFormat(item.item.amount) }}
            </template>
            <template #cell(more)="item">
              <div class="text-center d-flex">
                <b-link
                  v-b-modal.modal-weight
                  class="text-secondary mx-1"
                  @click="getData(item.item.id, 2)"
                >
                  <feather-icon icon="Edit3Icon" class="mr-50" />
                </b-link>
                <div class="text-danger" @click="handleDelete(item.item.id, 2)">
                  <feather-icon icon="TrashIcon" />
                </div>
              </div>
            </template>
          </b-table>
          <div>
            <div>
              <b-button
                v-b-modal.modal-weight
                variant="primary"
                type="submit"
              >
                Add rate
              </b-button>
            </div>
          </div>
        </div>
      </Fieldset>
    </b-col>
    <b-col cols="12">
      <Fieldset id="options3" :title="$t('Shipping zone')">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2>Shipping zone</h2>
            <p>Add shipping zone based on the weight of products or the price of customer's order</p>
          </div>
          <div class="d-flex align-items-center">
            <div v-if="!itemsZone.length > 0">
              <b-button
                v-b-modal.modal-zone
                variant="primary"
                type="submit"
              >
                Set up Shipping zone
              </b-button>
            </div>
            <div class="d-flex ml-2">
              <b-form-checkbox
                v-model="model.shipping_mode"
                checked="true"
                class="custom-control-success"
                name="check-button"
                switch
                :value="3"
                @change="handleChangeShippingMode"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
              <span v-if="zoneActive">On</span>
              <span v-else>Off</span>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <b-table v-if="itemsZone.length > 0" responsive="sm" :fields="fieldsZone" :items="itemsZone">
            <template #cell(amount)="item">
              {{ currencyFormat(item.item.amount) }}
            </template>
            <template #cell(more)="item">
              <div class="text-center d-flex">
                <b-link
                  v-b-modal.modal-province
                  class="text-secondary mx-1"
                  @click="getData(item.item.id, 3)"
                >
                  <feather-icon icon="Edit3Icon" class="mr-50" />
                </b-link>
                <div class="text-danger" @click="handleDelete(item.item.id, 3)">
                  <feather-icon icon="TrashIcon" />
                </div>
              </div>
            </template>
          </b-table>
          <div>
            <div>
              <b-button
                v-b-modal.modal-province
                variant="primary"
                type="submit"
              >
                Add rate
              </b-button>
            </div>
          </div>
        </div>
      </Fieldset>
    </b-col>
    <!-- Modal setting price -->
    <b-modal
      id="modal-price"
      ref="price-modal-add"
      cancel-variant="outline-secondary"
      :ok-title="isEdit ? 'Update' : 'Add'"
      cancel-title="Cancel"
      centered
      size="lg"
      title="Add Price Based Rate"
      @hidden="handleCancel"
      @ok="handleAdd(1)"
      @cancel="handleCancel"
    >
      <validation-observer ref="coin_rule">
        <b-form>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <label>Name</label>
              <b-form-input
                v-model="model.price.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Minimum order price "
                  rules="required"
                >
                  <label>Minimum order price </label>
                  <b-form-input
                    v-model="model.price.min_price"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Maximum order price "
                  rules="required"
                >
                  <label>Maximum order price </label>
                  <b-form-input
                    v-model="model.price.max_price"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <hr>
              <b-form-group label="RATE" label-cols-md="12">
                <b-form-radio v-model="model.price.statusRate" :value="1">
                  Freeshipping
                </b-form-radio>
                <b-form-radio v-model="model.price.statusRate" :value="2" class="my-1">
                  Rate amount
                </b-form-radio>
                <b-input-group class="input-group-merge mt-1 w--145">
                  <b-form-input
                    v-model="model.price.amount"
                    type="number"
                    min="0"
                    :readonly="model.price.statusRate === 2 ? false : true"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Modal setting weight -->
    <b-modal
      id="modal-weight"
      ref="weight-modal-add"
      cancel-variant="outline-secondary"
      :ok-title="isEdit ? 'Update' : 'Add'"
      cancel-title="Close"
      centered
      size="lg"
      title="Add Weight Based Rate"
      @hidden="handleCancel"
      @ok="handleAdd(2)"
      @cancel="handleCancel"
    >
      <validation-observer ref="coin_rule">
        <b-form>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <label>Name</label>
              <b-form-input
                v-model="model.weight.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Minimum order weight "
                  rules="required"
                >
                  <label>Minimum order weight </label>
                  <b-input-group append="kg" class="input-group-merge w--145">
                    <b-form-input
                      v-model="model.weight.min_weight"
                      type="number"
                      min="0"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Maximum order weight "
                  rules="required"
                >
                  <label>Maximum order weight </label>
                  <!-- <b-form-input
                    v-model="model.weight.max"
                    :state="errors.length > 0 ? false:null"
                  /> -->
                  <b-input-group append="kg" class="input-group-merge w--145">
                    <b-form-input
                      v-model="model.weight.max_weight"
                      type="number"
                      min="0"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <hr>
              <b-form-group label="RATE" label-cols-md="12">
                <b-form-radio v-model="model.weight.statusRate" :value="1">
                  Freeshipping
                </b-form-radio>
                <b-form-radio v-model="model.weight.statusRate" :value="2" class="my-1">
                  Rate amount
                </b-form-radio>
                <b-input-group class="input-group-merge mt-1 w--145">
                  <b-form-input
                    v-model="model.weight.amount"
                    type="number"
                    min="0"
                    placeholder="10.000"
                    :readonly="model.weight.statusRate === 2 ? false : true"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Modal zone -->
    <b-modal
      id="modal-province"
      ref="province-modal-add"
      cancel-variant="outline-secondary"
      :ok-title="isEdit ? 'Update' : 'Add'"
      cancel-title="Close"
      centered
      size="lg"
      title="Add provinces"
      @hidden="handleCancel"
      @ok="handleAdd(3)"
      @cancel="handleCancel"
    >
      <validation-observer ref="coin_rule">
        <b-form>
          <!-- <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="model.name"
                type="text"
                placeholder="Search"
              />
            </b-input-group>
          </b-form-group> -->
          <!-- <hr> -->
          <div class="d-flex">
            <b-form-input
              v-model="model.zone.name"
              type="text"
              placeholder="Enter province name"
              class="w-100 mr-2"
            />
            <b-form-input
              v-model="model.zone.amount"
              type="number"
              placeholder="Enter shipping fee"
              class="w-100 mr-2"
            />
          </div>
          <!-- <b-button variant="primary" type="submit" class="mt-2">
            Add
          </b-button> -->
          <!-- <b-row>
            <b-col cols="12">
              <div class="container-ctrl">
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-for="(item, index) in listProvince"
                    :key="index"
                    v-model="selectedProvince"
                    :value="item.id"
                    name="lang-radio"
                    class="w-100 custom-check"
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <div>
                        {{ item.name }}
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-input
                          v-model="item.rate"
                          type="text"
                          placeholder="Enter shipping fee"
                          class="w-100 mr-2"
                        />
                        <b-link v-b-modal.modal-adjust @click="setPriceShip">
                          Adjust
                        </b-link>
                      </div>
                    </div>
                  </b-form-checkbox>
                </div>
              </div>
            </b-col>
          </b-row> -->
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Modal province (old) -->
    <!-- <b-modal
      id="modal-zone"
      ref="zone-modal-add"
      cancel-variant="outline-secondary"
      :ok-title="isEdit ? 'Update' : 'Add'"
      cancel-title="Close"
      centered
      size="lg"
      title="Add countries"
      @hidden="handleCancel"
      @ok="handleAdd(1)"
      @cancel="handleCancel"
    >
      <validation-observer ref="coin_rule">
        <b-form>
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="model.zone.name"
                type="text"
                placeholder="Search"
              />
            </b-input-group>
          </b-form-group>
          <hr>
          <b-row>
            <b-col cols="12">
              <div class="container-ctrl">
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-for="(item, index) in listCountry"
                    :key="index"
                    v-model="selectedCountry"
                    :value="item.id"
                    name="lang-radio"
                    class="w-100 custom-check"
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <div>
                        <img
                          style="width: 25px; height: 25px; object-fit: contain;"
                          class="mx-1"
                          :src="item.img"
                          :alt="item.name"
                        />
                        {{ item.name }}
                      </div>
                      <div>
                        <b-link v-b-modal.modal-province @click="getProvince(item.id)">
                          ({{ item.province }} province)
                        </b-link>
                      </div>
                    </div>
                  </b-form-checkbox>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal> -->

    <!-- Modal adjust list -->
    <!-- <b-modal
      id="modal-adjust"
      ref="adjust-modal-add"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      centered
      size="lg"
      title="Adjust the shipping cost:"
      @ok="handleAddProvince"
    >
      <b-table responsive="sm" :fields="fields" :items="itemsAdjust">
        <template #cell(more)="item">
          <div class="text-center d-flex">
            <b-link
              v-b-modal.modal-adjust-edit
              class="text-secondary mx-1"
              @click="getDataWeight(item.item.id)"
            >
              <feather-icon icon="Edit3Icon" class="mr-50" />
            </b-link>
            <div class="text-danger" @click="handleDelete(item.item.id)">
              <feather-icon icon="TrashIcon" />
            </div>
          </div>
        </template>
      </b-table>
      <div>
        <div>
          <b-button
            v-b-modal.modal-adjust-edit
            variant="primary"
            type="submit"
          >
            Add rate
          </b-button>
        </div>
      </div>
    </b-modal> -->
    <!-- Modal adjust add/edit -->
    <!-- <b-modal
      id="modal-adjust-edit"
      ref="adjust-edit-modal-add"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      centered
      size="lg"
      title="Adjust the shipping cost: Ho Chi Minh"
      @ok="handleAddAdjust"
    >
      <validation-observer ref="coin_rule">
        <b-form>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <label>Name</label>
              <b-form-input
                v-model="adjust.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <label>Shipping base on</label>
          <div class="d-flex mb-2">
            <b-form-radio v-model="adjust.status" :value="1">
              Product price
            </b-form-radio>
            <b-form-radio v-model="adjust.status" :value="2" class="ml-4">
              Product weight
            </b-form-radio>
          </div>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Minimum order"
                  rules="required"
                >
                  <label>Minimum order</label>
                  <b-input-group class="input-group-merge w--145">
                    <b-form-input
                      v-model="adjust.min"
                      type="number"
                      min="0"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Maximum order"
                  rules="required"
                >
                  <label>Maximum order</label>
                  <b-input-group class="input-group-merge w--145">
                    <b-form-input
                      v-model="adjust.max"
                      type="number"
                      min="0"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <hr>
              <b-form-group label="RATE" label-cols-md="12">
                <b-form-radio v-model="model.price.statusRate" :value="1">
                  Freeshipping
                </b-form-radio>
                <b-form-radio v-model="model.price.statusRate" :value="2" class="my-1">
                  Rate amount
                </b-form-radio>
                <b-input-group class="input-group-merge mt-1 w--145">
                  <b-form-input
                    v-model="model.price.amount"
                    type="number"
                    min="0"
                    placeholder="10.000"
                    :readonly="adjust.statusRate === 2 ? false : true"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr>
              <h4>SHIPPING COST FOR DISTRICTS</h4>
              <label>You can adjust the shipping cost for the districts in Ho Chi Minh City</label>
              <div class="container-ctrl">
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-for="(item, index) in listWard"
                    :key="index"
                    v-model="selectedWard"
                    :value="item.id"
                    name="lang-radio"
                    class="w-100 custom-check"
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <div>
                        {{ item.name }}
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-input
                          v-model="item.rate"
                          type="text"
                          placeholder="Enter shipping fee"
                          class="w-100 mr-2"
                        />
                        <span>25000</span>
                      </div>
                    </div>
                  </b-form-checkbox>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal> -->
  </b-row>
</template>
<script>
/* eslint-disable no-unneeded-ternary */
/* eslint-disable vue/valid-template-root */

import {
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BForm,
  BFormRadio,
  BInputGroup,
  // BInputGroupPrepend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import general from '@/mixins/index'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BFormGroup,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BForm,
    BFormRadio,
    BInputGroup,
    // BInputGroupPrepend,
  },
  mixins: [general],
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      key: 'buso_client_page_config',
      config: [
        {
          key: '',
          value: '',
        },
      ],
      isExistSetting: false,
      required,
      model: {
        shipping_mode: 0,
        price: {
          type: 1,
          name: null,
          max_price: 0,
          min_price: 0,
          amount: 0,
          is_active: true,
          statusRate: 1,
        },
        weight: {
          type: 2,
          name: null,
          max_weight: 0,
          min_weight: 0,
          amount: 0,
          is_active: true,
          statusRate: 1,
        },
        zone: {
          type: 3,
          name: null,
          amount: 0,
          is_active: true,
        },
      },
      isEdit: false,
      // Price
      priceActive: true,
      itemsPrice: [],
      fieldsPrice: ['name', 'range', 'amount', 'more'],
      // Weight
      weightActive: false,
      itemsWeight: [],
      fieldsWeight: ['name', 'range', 'amount', 'more'],
      // Zone
      zoneActive: false,
      itemsZone: [],
      fieldsZone: ['name', 'amount', 'more'],

      // selectedList: [],
      // selectedCountry: [],
      // selectedProvince: [],
      // selectedWard: [],

      // itemsAdjust: [
      //   {
      //     rate_amount: 0,
      //     name: 'Free Ship Adjust',
      //     range: '0-20.000',
      //     id: 1,
      //   },
      //   {
      //     rate_amount: 0,
      //     name: 'Free Ship',
      //     range: '0-20.000',
      //     id: 2,
      //   },
      // ],
      // adjust: {
      //   name: null,
      //   status: 1,
      //   min: null,
      //   max: null,
      //   rate: null,
      //   statusRate: 1,
      // },
      // addProvince: {
      //   name: null,
      //   rate: null,
      // },
      // listCountry: [
      //   {
      //     id: 1,
      //     img: '/',
      //     name: 'Viet Nam',
      //     province: 48,
      //   },
      //   {
      //     id: 2,
      //     img: '/',
      //     name: 'Cambodia',
      //     province: 28,
      //   },
      // ],
      // listProvince: [
      //   {
      //     id: 1,
      //     name: 'Hà Nội',
      //     rate: null,
      //   },
      //   {
      //     id: 2,
      //     name: 'Thành phố Hồ Chí minh',
      //     rate: null,
      //   },
      // ],
      // listWard: [
      //   {
      //     id: 1,
      //     name: 'Quận 1',
      //     rate: null,
      //   },
      //   {
      //     id: 2,
      //     name: 'Quận 2',
      //     rate: null,
      //   },
      // ],
    }
  },
  watch: {
    async priceActive() {
      if (this.priceActive) {
        this.weightActive = false
        this.zoneActive = false
      }
    },
    async weightActive() {
      if (this.weightActive) {
        this.priceActive = false
        this.zoneActive = false
      }
    },
    async zoneActive() {
      if (this.zoneActive) {
        this.weightActive = false
        this.priceActive = false
      }
    },
  },
  async mounted() {
    this.loadConfig()
    this.itemsPrice = await this.loadShipping(1)
    this.itemsWeight = await this.loadShipping(2)
    this.itemsZone = await this.loadShipping(3)
  },
  methods: {
    async loadConfig() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/setting/${this.key}?site_id=${
            JSON.parse(localStorage.getItem('siteID')).id
          }`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.isExistSetting = true
            this.config = JSON.parse(res.data.data.value)
            if (this.config.find(x => x.key === 'shipping_mode') !== undefined) {
              this.model.shipping_mode = this.config.find(x => x.key === 'shipping_mode').value
            }
          } else {
            this.isExistSetting = false
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleChangeShippingMode() {
      try {
        if (this.config.find(val => val.key === 'shipping_mode') === undefined) {
          this.config.push({
            key: 'shipping_mode',
            value: this.model.shipping_mode,
          })
        } else {
          this.config.find(val => val.key === 'shipping_mode').value = this.model.shipping_mode
        }
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          key: this.key,
          value: JSON.stringify(this.config),
        }
        let res = null
        if (!this.isExistSetting) {
          res = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/settings`,
            params,
          )
        } else {
          res = await Request.put(
            this.$http,
            `${process.env.VUE_APP_API_URL}/setting/${this.key}`,
            params,
          )
        }
        if (res.status === 200) {
          if (res.data.status) {
            // this.loadConfig()
            this.isExistSetting = true
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadShipping(type) {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shippings?site_id=${siteId}&type=${type}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            return res.data.data.items
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async getData(id, type) {
      try {
        this.isEdit = true
        this.model.id = id
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shipping/${id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            if (type === 1) {
              this.model.price = {
                type,
                name: res.data.data.name,
                min_price: res.data.data.min_price,
                max_price: res.data.data.max_price,
                amount: res.data.data.amount,
                statusRate: res.data.data.amount === 0 ? 1 : 2,
                is_active: Boolean(this.model.price.is_active === 1 ? true : false),
              }
            }
            if (type === 2) {
              this.model.weight = {
                type,
                name: res.data.data.name,
                min_weight: res.data.data.min_weight,
                max_weight: res.data.data.max_weight,
                amount: res.data.data.amount,
                statusRate: res.data.data.amount === 0 ? 1 : 2,
                is_active: Boolean(this.model.weight.is_active === 1 ? true : false),
              }
            }
            if (type === 3) {
              this.model.zone = {
                type,
                name: res.data.data.name,
                amount: res.data.data.amount,
                is_active: Boolean(this.model.weight.is_active === 1 ? true : false),
              }
            }
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async handleAdd(type) {
      let params = {
      }
      if (type === 1) { // Shipping price
        params = {
          type,
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          name: this.model.price.name,
          min_price: Number(this.model.price.min_price),
          max_price: Number(this.model.price.max_price),
          amount: this.model.price.statusRate === 1 ? 0 : Number(this.model.price.amount),
          is_active: 1,
        }
      }
      if (type === 2) {
        params = {
          type,
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          name: this.model.weight.name,
          min_weight: Number(this.model.weight.min_weight),
          max_weight: Number(this.model.weight.max_weight),
          amount: this.model.weight.statusRate === 1 ? 0 : Number(this.model.weight.amount),
          is_active: 1,
        }
      }
      if (type === 3) {
        params = {
          type,
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          name: this.model.zone.name,
          amount: Number(this.model.zone.amount),
          is_active: 1,
        }
      }

      if (this.isEdit) {
        this.handleSubmit('put', type, params, this.model.id)
      } else {
        this.handleSubmit('post', type, params)
      }
    },
    handleCancel() {
      this.isEdit = false
      this.model.id = null
      this.model = {
        price: {
          type: 1,
          name: null,
          max_price: 0,
          min_price: 0,
          amount: 0,
          is_active: true,
          statusRate: 1,
        },
        weight: {
          type: 2,
          name: null,
          max_weight: 0,
          min_weight: 0,
          amount: 0,
          is_active: true,
          statusRate: 1,
        },
        zone: {
          type: 3,
          name: null,
          amount: 0,
          is_active: true,
        },
      }
    },
    async handleDelete(id, type) {
      try {
        this.$swal({
          title: 'Are you sure?',
          // text: 'Sản phẩm hiện tài có thuộc tính còn số lượng trong kho, ban có muốn xóa hay ko !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const siteId = JSON.parse(localStorage.getItem('siteID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/shipping/${id}?site_id=${siteId}`,
            )
            if (res.data.status) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                // text: 'Your Product has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              if (type === 1) {
                this.itemsPrice = await this.loadShipping(1)
              }
              if (type === 2) {
                this.itemsWeight = await this.loadShipping(2)
              }
              if (type === 3) {
                this.itemsZone = await this.loadShipping(3)
              }
            } else {
              this.$swal({
                icon: 'error',
                title: 'Error!',
                text: String(this.showError(res.data.error, ',')),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleSubmit(method, type, params, id) {
      try {
        let res = null
        if (method === 'post') {
          res = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/shipping`,
            params,
          )
        }
        if (method === 'put') {
          res = await Request.put(
            this.$http,
            `${process.env.VUE_APP_API_URL}/shipping/${id}`,
            params,
          )
        }

        if (res.status === 200) {
          if (res.data.status) {
            this.model = {
              price: {
                type: 1,
                name: null,
                max_price: 0,
                min_price: 0,
                amount: 0,
                is_active: true,
                statusRate: 1,
              },
              weight: {
                type: 2,
                name: null,
                max_weight: 0,
                min_weight: 0,
                amount: 0,
                is_active: true,
                statusRate: 1,
              },
              zone: {
                type: 3,
                name: null,
                amount: 0,
                is_active: true,
              },
            }
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Congratulation !',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Create shippingg price success',
              },
            })
            if (type === 1) {
              this.itemsPrice = await this.loadShipping(1)
            }
            if (type === 2) {
              this.itemsWeight = await this.loadShipping(2)
            }
            if (type === 3) {
              this.itemsZone = await this.loadShipping(3)
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
<style lang="sass">
.custom-check
  &:not(:last-child)
    border-bottom: 1px solid #ccc
    padding-bottom: 20px
.custom-control-label
  width: 100%
  span
    color: var(--primary)
</style>
<style lang="sass" scoped>
.container-ctrl
  padding-left: 1rem
  padding-right: 1rem

  .custom-control-label
    width: 100%
  .box-cate
    background-color: #F7F7F7
    padding: 1rem

    .cate-list
      background-color: #fff
      margin-top: 1rem
      padding: 1rem
      min-height: 25rem

      .list-item
        padding-left: 0
        overflow-y: scroll
        height: 25rem

        .active
          font-weight: 500
          background-color: #f5f5f5
        .item
          display: flex
          justify-content: space-between
          list-style: none
          padding: 10px 15px
          // text-decoration: none
          color: #000
          cursor: pointer
          &.d-none-after
            &::after
              display: none
              opacity: 0

          & > span
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

          &:hover
            font-weight: 500
            background-color: #f5f5f5

          &::after

            content: ""
            display: inline-block
            vertical-align: middle
            width: 6px
            height: 13px
            margin-left: 1rem
            transition: transform 350ms
            transform: rotate(0)
            border-width: .5rem 0 .5rem .5rem
            border-color: transparent transparent transparent #ccc
            border-style: solid
  .current
    margin-top: 1rem
</style>
